$footer-background: color(global-background);
$footer-background--large: $footer-background;
$footer-newsletter-signup-title-color: color(dark);
$footer-newsletter-color: color(dark);
$footer-newsletter-color--large: $footer-newsletter-color;
$footer-newsletter-captcha-link-color: color(dark);
$footer-newsletter-captcha-link-color--large: $footer-newsletter-captcha-link-color;
$footer-newsletter-captcha-link-hover-color: color(secondary-active);
$footer-newsletter-captcha-text-color: color(dark);
$footer-newsletter-captcha-text-color--large: $footer-newsletter-captcha-text-color;
$footer-newsletter-link-color: color(dark);
$footer-newsletter-link-color--large: $footer-newsletter-link-color;
$footer-newsletter-link-hover-color: color(secondary-active);
$footer-newsletter-font: 500 #{rem-calc(12)} / 1.8 $font-primary;
$footer-newsletter-link-font: 100 #{rem-calc(12)} / 1.8 $font-primary;
$footer-newsletter-captcha-text-font: 500 #{rem-calc(12)} / 1.8 $font-primary;
$footer-newsletter-captcha-link-font: 100 #{rem-calc(12)} / 1.8 $font-primary;
$footer-row-item-margin: rem-calc(0 0 20);
$footer-row-item-last-padding-left: 0;
$footer-social-margin: rem-calc(0 0 20);
$footer-row-variants-flex-direction: column-reverse;
$footer-bottom-border-top: rem-calc(1) solid color(border);
$footer-error-color: color(alert);

@import "@lora/04-layout/footer";