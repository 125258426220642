// Items lists
$header-navigation-list-level-1-justify-content--large: space-between;
$header-navigation-list-level-3-margin--large: rem-calc(5 0 0);
$header-navigation-list-level-2-padding--large: rem-calc(5 0);
$header-navigation-list-level-2-margin--large: 0;

// Items
$header-navigation-item-level-2-padding--large: rem-calc(5 0 5);
$header-navigation-item-level-3-padding--large: rem-calc(6 0);
$header-navigation-item-level-3-all-link-color--large: color(dark);
$header-navigation-item-level-3-all-link-font: 400 #{rem-calc(14)} / 1.2 $font-primary;
$header-navigation-item-level-3-all-link-font--large: $header-navigation-item-level-3-all-link-font;
$header-navigation-item-level-3-all-link-text-decoration--large: underline;

// Items titles
$header-navigation-item-title-padding: rem-calc(16);
$header-navigation-item-title-level-1-font: 400 #{rem-calc(14)} / 1.2 $font-primary;
$header-navigation-item-title-level-1-font--large: $header-navigation-item-title-level-1-font;
$header-navigation-link-level-1-padding--large: rem-calc(17 12 18);
$header-navigation-item-title-level-1-padding-left: rem-calc(16);
$header-navigation-item-title-level-1-expanded-background: color(light);
$header-navigation-item-title-level-1-expanded-color: color(dark);
$header-navigation-item-title-level-2-padding-left: rem-calc(24);
$header-navigation-item-title-level-2-expanded-background: color(global-background);
$header-navigation-item-title-level-2-expanded-color: color(global-background);
$header-navigation-item-title-level-3-background: color(secondary);
$header-navigation-item-title-level-2-font: 400 #{rem-calc(14)} / 1.2 $font-primary;
$header-navigation-item-title-level-1-expanded-font: 700 #{rem-calc(14)} / 1.2 $font-primary;
$header-navigation-item-title-level-2-expanded-font: $header-navigation-item-title-level-1-expanded-font;
$header-navigation-link-level-3-font: $header-navigation-item-title-level-1-font;

/// Item level 1
$header-navigation-item-level-1-border-bottom: 1px solid color(disabled);

/// Item level 2
$header-navigation-item-level-2-expanded-border: 1px solid color(disabled);

// Item accordion controls
$header-navigation-item-title-accordion-level-1-expanded-border: 1px solid color(disabled);

/// Accordion icon
$header-navigation-item-title-accordion-icon-size: rem-calc(12);

//View all link in level 2
$header-navigation-item-level-2-all-link-font: 400 #{rem-calc(14)} / 1.2 $font-primary;
$header-navigation-item-level-2-all-link-text-decoration: underline;

/// View all link in level 3
$header-navigation-item-level-3-all-link-font: 400 #{rem-calc(14)} / 1.2 $font-primary;
$header-navigation-item-level-3-all-link-text-decoration: underline;
$header-navigation-item-level-3-all-link-color: color(text-secondary);
$header-navigation-item-level-3-all-link-color--large: $header-navigation-item-level-3-all-link-color;
$header-navigation-item-level-3-all-link-text-decoration--large: none;

// Links
$header-navigation-link-level-1-font-weight--large: 700;
$header-navigation-link-level-1-font-family: $font-primary;
$header-navigation-link-level-1-letter-spacing--large: 0.3px;
$header-navigation-link-level-1-color: color(primary);
$header-navigation-link-level-1-color--large: inherit;
$header-navigation-link-level-1-hover-font-weight: 700;
$header-navigation-link-level-1-text-transform: none;
$header-navigation-link-level-1-text-transform--large: uppercase;
$header-navigation-link-level-2-color: color(dark);
$header-navigation-link-level-2-font-size: rem-calc(14);
$header-navigation-link-level-2-hover-color: color(text-secondary);
$header-navigation-link-level-2-font-weight--large: 700;
$header-navigation-item-title-level-2-font: 400 #{rem-calc(14)} / 1.2 $font-primary;
$header-navigation-item-title-level-2-font--large: $header-navigation-item-title-level-2-font;
$header-navigation-link-level-2-hover-font-weight: 700;
$header-navigation-link-level-2-text-transform--large: uppercase;
$header-navigation-link-level-2-font-size--large: rem-calc(14);
$header-navigation-link-level-3-font: 400 #{rem-calc(14)} / 1.2 $font-primary;
$header-navigation-link-level-3-font-size--large: rem-calc(14);
$header-navigation-link-level-3-padding--medium-down: rem-calc(11 8 11 48);
$header-navigation-link-level-3-color: color(text-secondary);
$header-navigation-link-level-3-color--large: color(text-secondary);
$header-navigation-link-level-3-hover-font-weight: normal;
$header-navigation-link-level-3-hover-color: color(text-secondary);
$header-navigation-item-level-3-all-link-hover-color: color(secondary-active);

// Flyout
$header-navigation-flyout-element-background--large: color(light);
$header-navigation-flyout-element-margin-left--large: rem-calc(-23);
$header-navigation-flyout-element-border-top--large: none;
$header-navigation-flyout-element-box-shadow--large: rem-calc(0 0 6 0) rgba(color(primary), 0.02), rem-calc(0 2 4 0) rgba(color(primary), 0.08);

// Items
$header-navigation-item-level-1-flex-grow--large: 1;

// Left and right icon
$header-navigation-item-icon-link-padding--large: 0;

// Item accordion controls
$header-navigation-item-title-accordion-padding: rem-calc(16 50 16 0);

// Container
$header-navigation-container-content-padding: rem-calc(8 16 16);
$header-navigation-container-content-padding--xlarge: 0 0 rem-calc(28);

$header-navigation-container-grid-padding--xlarge: rem-calc(24 5 28 0);

@import "@lora/05-components/header/header-navigation";