// Name and subtitle
$product-tile-horizontal-name-link-text-transform: uppercase;
$product-tile-horizontal-name-letter-spacing: rem-calc(0.26);
$product-tile-horizontal-subtitle-margin: rem-calc(0 0 15);
$product-tile-horizontal-subtitle-margin--large: rem-calc(10 0 15);
$product-tile-horizontal-info-item-font-weight--large: 500;
$product-tile-horizontal-name-max-lines--large: 2;
$product-tile-horizontal-name-max-lines: 2;
$product-tile-horizontal-name-font-size: rem-calc(14);
$product-tile-horizontal-name-font-weight: 500;
$product-tile-horizontal-variations-padding--large: rem-calc(0 0 0 10);
$product-tile-horizontal-figure-width: rem-calc(60);
$product-tile-horizontal-figure-width--large: rem-calc(60);
$product-tile-horizontal-secondary-figure-width: $product-tile-horizontal-figure-width;
$product-tile-horizontal-secondary-figure-width--large: $product-tile-horizontal-figure-width;
$product-tile-horizontal-figure-margin--large: rem-calc(0 8 0 0);
$product-tile-horizontal-variation-margin--large: rem-calc(0 0 20);
$product-tile-horizontal-quantity-flex-grow--large: 1;
$product-tile-horizontal-info-item-flex-grow--large: 0;
$product-tile-horizontal-info-justify-content--large: space-between;
$product-tile-horizontal-info-item-padding--large: rem-calc(0 50 0 0);
$product-tile-horizontal-actions-padding: rem-calc(0);
$product-tile-horizontal-info-margin--large: rem-calc(0 10 0 0);
$product-tile-horizontal-table-margin: rem-calc(0);
$product-tile-horizontal-info-flex--large: 1 0 0;
$product-tile-horizontal-tertiary-figure-width--large: rem-calc(100);

@import "@lora/05-components/product/product-tile-horizontal";