@import '@root/05-components/stacked-links';

@mixin jp-lancome-components-stacked-links {
    .l-account__main {
        .c-sidebar-navigation {
            .c-sidebar-navigation__links.c-stacked-links:last-child {
                display: block;
            }
        }
    }

    .c-product-badge {
        .c-product-badge__title {
            background: none;
            color: $badge-title-color;
        }
    }
}