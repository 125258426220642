$form-select-font-sizes: (
    "small": rem-calc(12),
    "normal": rem-calc(12),
    "large": rem-calc(12)
);

$form-select-size: (
    "small": rem-calc(25),
    "normal": $global-input-primary-height,
    "large": rem-calc(50)
);

// Default select
$form-select-height: map-get($form-select-size, 'large');
$form-select-label-float-margin: 0 rem-calc(13);
$form-select-alt-padding: rem-calc(0 30 0 20);

//Third
$form-select-third-background: color(dark);
$form-select-third-border-color: color(border);
$form-select-third-radius: rem-calc(5);
$form-select-third-color: color(light);
$form-select-third-text-transform: uppercase;
$form-select-third-arrow-color: color(light);
$form-select-third-option-color: color(dark);

$form-select-border-color: color(border);
$form-select-border-radius: rem-calc(5);
$form-select-font-weight: 500;

// Hover state
$form-select-font-weight-hover: 500;

// Panel
$form-select-panel-item-background-hover: color(secondary);
$form-select-panel-item-background-selected: color(secondary);
$form-select-panel-item-color-hover: color(secondary-active);
$form-select-panel-item-color-selected: color(secondary-active);
$form-select-panel-item-font: 300 #{rem-calc(12)} / rem-calc(16) $font-primary;
$form-select-panel-item-font-hover: 500 #{rem-calc(12)} / rem-calc(16) $font-primary;
$form-select-panel-item-font-selected: 500 #{rem-calc(12)} / rem-calc(16) $font-primary;

// Single value
$form-select-single-value-label-float-left: rem-calc(-13);

// Select badge
$form-select-badge-font: 700 #{rem-calc(11)} / rem-calc(13) $font-primary;
$form-select-badge-padding: rem-calc(2 4);
$form-select-badge-text-transform: uppercase;

@import "@lora/05-components/form/select";