// Product bundle title
$product-bundle-title-after-margin: rem-calc(-5 10 0 0);
$product-bundle-title-after-expanded-margin: rem-calc(0 10 0 0);
$product-bundle-title-text-transform: none;
$product-bundle-title-font: 400 #{rem-calc(18)} / #{rem-calc(27)} $font-secondary;
$product-bundle-title-name-font--medium-down: 500 #{rem-calc(14)} / 1 $font-primary;
$product-bundle-title-qty-font: 700 #{rem-calc(14)} / rem-calc(17) $font-primary;
$product-bundle-title-border-bottom: 0;

// Plus icon
$product-bundle-icon-size: rem-calc(43);
$product-bundle-icon-size--large: $product-bundle-icon-size;

@import "@lora/05-components/product/product-bundle";