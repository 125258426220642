$header-hamburger-content-alignment--large: center;
$header-hamburger-item-link-transform: none;
$header-hamburger-item-link-color: color(dark);
// Item link
$header-hamburger-item-link-padding: rem-calc(16 0 8);
$header-hamburger-button-width: rem-calc(50);
$header-hamburger-button-padding: rem-calc(15 16 13 10);
$header-hamburger-button-icon-height: rem-calc(20);
$header-hamburger-button-icon-width: rem-calc(20);

// localization
$header-hamburger-localization-button-padding: rem-calc(17 42 17 20);
$header-hamburger-localization-button-icon-side: rem-calc(20);

/// Quicklinks
$header-hamburger-item-quicklinks-border: 1px solid color(disabled);
$header-hamburger-item-quicklinks-border-width: rem-calc(1 0);

@import "@lora/05-components/header/header-hamburger";