$modal-text-font-size: rem-calc(14);
$modal-text-margin: rem-calc(0 0 40);
$modal-text-text-align: left;
$modal-text-large-font-size: rem-calc(18);
$modal-text-line-height: 1.6;
$modal-text-large-line-height: $modal-text-line-height;
$modal-text-large-font-weight: $global-weight-normal;
$modal-text-large-font: $modal-text-large-font-weight #{$modal-text-large-font-size} / $modal-text-large-line-height $font-secondary;
$modal-wrapper-padding: rem-calc(40 15);
$modal-wrapper-padding--large: rem-calc(45 55);
$modal-subtitle-font: 500 #{rem-calc(18)} / 1.25 $font-primary;
$modal-subtitle-font--large: 500 #{rem-calc(18)} / 1.25 $font-primary;
$modal-body-font: #{rem-calc(14)} / 1.5 $font-primary;

// Header
$modal-title-margin: rem-calc(0 0 10);
$modal-title-margin--large: rem-calc(0 0 10);
$modal-title-font: 300 #{rem-calc(26)} / 1.15 $font-secondary;
$modal-title-font--large: 300 #{rem-calc(34)} / 1.15 $font-secondary;
$modal-header-font: #{rem-calc(26)} / rem-calc(30) $font-secondary;
$modal-header-font--large: $modal-header-font;
$modal-message-text-align: left;
$modal-text-margin: rem-calc(0 0 10);

// Modal with image
$modal-image-padding: rem-calc(0 0 25);
$modal-image-content-padding: rem-calc(25 16 0);
$modal-image-content-text-align: left;
$modal-image-content-width--large: 50%;
$modal-image-width--large: 50%;
$modal-image-max-width--large: rem-calc(770);

/// Action sticky
$modal-actions-sticky-box-shadow: 0;

$modal-actions-button-offset: rem-calc(5);
$modal-actions-button-offset--medium: rem-calc(5);
$modal-close-icon-size--large: rem-calc(13.71);

// Message
$modal-message-font-size: rem-calc(14);
$modal-message-font-weight: 400;
$modal-message-font: $modal-message-font-weight #{$modal-message-font-size} $font-secondary;

// Modal with secondary header (separated with border)
/// Title
$modal-header-secondary-title-text-align: left;
$modal-header-secondary-title-padding: rem-calc(15 0);

@import "@lora/05-components/modal";