$product-badge-margin: rem-calc(3 0 10);
$product-badge-image-max-width: rem-calc(80);
$product-badge-image-max-width--large: rem-calc(100);
$product-badge-image-margin: rem-calc(2 0);
$product-badge-image-margin--large: $product-badge-image-margin;

// Product badge title
$product-badge-title-padding: rem-calc(3 8);
$product-badge-title-background: rgba(color(dark), 0.5);
$product-badge-title-color: color(light);
$product-badge-title-font: 450 #{rem-calc(11)} / rem-calc(13) $font-primary;
$product-badge-title-font--large: $product-badge-title-font;
$product-badge-title-text-decoration: none;
$product-badge-title-text-transform: uppercase;
$product-badge-title-margin: rem-calc(2 0);

// Product badge category title
$product-badge-category-title-font: 300 #{rem-calc(10)} / rem-calc(16) $font-primary;
$product-badge-category-title-border-radius: rem-calc(4);
$product-badge-category-title-padding: rem-calc(3 8);
$product-badge-category-title-border-left: rem-calc(4) solid color(secondary-active);
$product-badge-category-title-box-shadow: rem-calc(0 0 2 0) rgba(color(primary), 0.2);

// Product badge title in variations
$product-badge-title-variations-font: 700 #{rem-calc(11)} / rem-calc(13) $font-primary;
$product-badge-title-variations-padding: rem-calc(2 4);

@import "@lora/05-components/product/product-badge";