$add-to-wishlist-border: none;
$add-to-wishlist-icon-size: rem-calc(19);
$add-to-wishlist-icon-size--large: $add-to-wishlist-icon-size;
$add-to-wishlist-icon-hover-color: color(secondary-active);
$add-to-wishlist-padding: rem-calc(10);
$add-to-wishlist-padding--large: $add-to-wishlist-padding;
$add-to-wishlist-border-radius: 50%;
$add-to-wishlist-box-shadow: none;
// Small
$add-to-wishlist-small-icon-size: rem-calc(12);
$add-to-wishlist-small-padding: rem-calc(6);

@import "@lora/05-components/add-to-wishlist";