$product-lineitem-highlight-padding: rem-calc(5 10);
$product-lineitem-highlight-padding--large: rem-calc(10 20);
$product-lineitem-highlight-margin: rem-calc(5 0);
$product-lineitem-highlight-margin--large: rem-calc(10 0);

// Title
$product-lineitem-highlight-title-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-tertiary;
$product-lineitem-highlight-title-font--large: $product-lineitem-highlight-title-font;
$product-lineitem-highlight-title-border-bottom: rem-calc(1) solid color(border);
$product-lineitem-highlight-title-padding: rem-calc(0 0 22);
$product-lineitem-highlight-title-margin: rem-calc(0 0 10);
$product-lineitem-highlight-title-margin--large: $product-lineitem-highlight-title-margin;

// Action
$product-lineitem-highlight-action-text-transform: none;
$product-lineitem-highlight-action-text-transform--large: $product-lineitem-highlight-action-text-transform;
$product-lineitem-highlight-action-color: color(primary) !default;
$product-lineitem-highlight-action-color--large: $product-lineitem-highlight-action-color;
$product-lineitem-highlight-action-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$product-lineitem-highlight-action-font--large: $product-lineitem-highlight-action-font;

// label
$product-lineitem-highlight-label-font: 500 #{rem-calc(12)} / 1.2 $font-tertiary;
$product-lineitem-highlight-label-font--large: $product-lineitem-highlight-label-font;

// Text
$product-lineitem-highlight-value-color: color(text-secondary);
$product-lineitem-highlight-value-color--large: $product-lineitem-highlight-value-color;
$product-lineitem-highlight-value-font: 300 #{rem-calc(11)} / 1.2 $font-primary;
$product-lineitem-highlight-value-font--large: $product-lineitem-highlight-value-font;

@import "@lora/05-components/product/product-lineitem-highlight";