$content-tile-stack-gutter: rem-calc(15);
// Content tile
$content-tile-title-font: 500 #{rem-calc(14)} / rem-calc(20) $font-tertiary;
$content-tile-title-font--large: $content-tile-title-font;

// Content tile label
$content-tile-label-display: inline-block;
$content-tile-label-display--large: $content-tile-label-display;
$content-tile-label-padding: rem-calc(5 23);
$content-tile-label-padding--large: rem-calc(5 23);
$content-tile-label-border-radius: rem-calc(5);
$content-tile-label-border: rem-calc(1) solid color(alert);
$content-tile-label-border--large: rem-calc(1) solid color(alert);
$content-tile-label-border-width: rem-calc(0 0 0 5);
$content-tile-label-border-width--large: rem-calc(0 0 0 5);
$content-tile-label-box-shadow: $global-shadow;
$content-tile-label-box-shadow--large: $global-shadow;
$content-tile-label-text-decoration: none;
$content-tile-label-text-decoration--large: none;
$content-tile-label-text-transform: none;

// Content tile large title
$content-tile-large-title-font: 500 #{rem-calc(14)} / rem-calc(20) $font-primary;
$content-tile-large-title-font--large: $content-tile-large-title-font;
$content-tile-large-title-text-transform--large: uppercase;

// Content tile large label
$content-tile-large-label-font: 500 #{rem-calc(12)} / rem-calc(16) $font-primary;
$content-tile-large-label-font--large: $content-tile-large-label-font;
$content-tile-large-label-margin: rem-calc(0 0 15);
$content-tile-large-label-margin--large: $content-tile-large-label-margin;
$content-tile-large-label-text-transform: none;
$content-tile-large-label-text-transform--large: $content-tile-large-label-text-transform;

// Description
$content-tile-description-font: 300 #{rem-calc(12)} / rem-calc(16) $font-primary-light;
$content-tile-description-font--large: #{rem-calc(14)} / rem-calc(20) $font-primary;
$content-tile-description-color: color(text-secondary);

// Description large
$content-tile-large-description-font: #{rem-calc(14)} / rem-calc(20) $font-primary;

// Tile Video
$content-tile-video-icon-color: color(light);

/// Title
$content-tile-title-margin: rem-calc(0 0 4);
$content-tile-title-margin--large: rem-calc(0 0 15);

@import "@lora/05-components/content-tile";