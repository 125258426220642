$header-user-menu-background: color(primary);
$header-user-menu-color: color(secondary);
$header-user-menu-font: 400 #{rem-calc(14)} / 1.2 $font-primary;
$header-user-menu-justify-content: left;
$header-user-menu-content-link-logout-background: color(secondary);
$header-user-menu-content-title-text-transform--medium-down: uppercase;
$header-user-menu-content-title-font-size: rem-calc(14);
$header-user-menu-content-link-font-size: rem-calc(12);
$header-user-menu-back-button-font: 500 #{rem-calc(12)} / rem-calc(16) $font-primary;
$header-user-menu-title-flex-direction: row;
$header-user-menu-title-margin: rem-calc(0 5 0 0);
$header-user-menu-content-link-logout-border-radius: rem-calc(5);
$header-user-menu-secondary-list-margin: rem-calc(30 55);
$header-user-menu-content-link-logout-margin: rem-calc(20 0 0);
$header-user-menu-content-link-logout-text-transform: uppercase;
$header-user-menu-secondary-link-height: rem-calc(40);

// Logout cta
$header-user-menu-logout-link-color: color(secondary);
$header-user-menu-logout-link-font: 400 #{rem-calc(14)} / 1.2 $font-primary;

// Title
$header-user-menu-title-font: 400 #{rem-calc(14)} / 1.2 $font-primary;
$header-user-menu-title-color: color(secondary);

/// Title name
$header-user-menu-title-name-link-color: color(secondary);

// Sign in
$header-user-menu-sign-in-color: color(secondary);

/// Sign in icon
$header-user-menu-icon-color: color(secondary);

// Menu link
$header-user-menu-link-color: color(secondary);

@import "@lora/05-components/header/header-user-menu";