$content-panel-background: color(secondary-active);
$content-panel-font-color: color(light);
$content-panel-font: 100 #{rem-calc(12)} / rem-calc(16) $font-primary;
$content-panel-close-color: $content-panel-font-color;
$content-panel-close-font-size: rem-calc(30);
$content-panel-close-font-weight: 100;
$content-panel-close-top: rem-calc(-3);
$content-panel-padding: rem-calc(10 30);
$content-panel-text-align: center;
$content-panel-link-hover-color: color(light);

@import "@lora/05-components/content-panel";