@import '@root/05-components/checkout/checkout-samples';

/* stylelint-disable */
.chmodule_samples {
    .sample_detail {
        @include breakpoint(medium down) {
            height: rem-calc(280);
        }
    }

    .sample_subtitle {
        min-height: rem-calc(78);
    }
}