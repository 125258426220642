$form-check-field-checkbox-checked-icon-width: rem-calc(20);
$form-check-field-checkbox-checked-icon-height: rem-calc(20);
$form-check-field-checkbox-checked-icon-position--top: 0;
$form-check-field-checkbox-checked-icon-position--left: 0;
$form-check-field-checkbox-checked-icon: tick;
$form-check-field-checkbox-checked-icon-color: color(dark);
$form-check-field-checkbox-checked-control-background: color(light);
$form-check-field-radio-control-after-border: rem-calc(1) solid color(border);
$form-check-field-radio-control-margin-top: 0;
$form-check-field-radio-checked-control-border-after: rem-calc(0.6) solid color(primary);
$form-check-field-checkbox-checked-control-font-weight: 500;
$form-check-field-radio-control-size: rem-calc(43);
$form-check-field-radio-checked-control-border-width: #{$form-check-field-radio-control-size / 6};
$form-check-field-label-height: rem-calc(22);
$form-check-field-label-font-size: rem-calc(12);
$form-check-field-radio-control-after-left: 0;
$form-check-field-radio-control-after-top: 0;
$form-check-field-radio-control-after-size: rem-calc(20);
$form-check-field-image-checked-label-border: rem-calc(1) solid color(primary-active);
$form-check-field-checkbox-checked-control-font-weight: 500;
$form-check-field-switch-after-background: color(organism-background);
$form-check-field-switch-after-height: rem-calc(16);
$form-check-field-switch-after-width: rem-calc(16);
$form-check-field-switch-after-position--top: rem-calc(3);
$form-check-field-switch-after-position--right: rem-calc(17);
$form-check-field-switch-before-background: color(border);
$form-check-field-switch-before-height: rem-calc(22);
$form-check-field-switch-before-border-radius: rem-calc(20);
$form-check-field-switch-checked-after-background: color(secondary);
$form-check-field-switch-checked-after-position--right: rem-calc(2);
$form-check-field-switch-checked-before-background: color(secondary-active);
$form-check-field-switch-checked-font-weight: 500;
$form-check-field-disabled-control-background: color(disabled);

@import "@lora/05-components/form/check-field";