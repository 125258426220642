/// info promotion
/// @type Map
$promotion-info: (
    color: color(dark),
    icon: 'info',
    icon-color: color(dark)
);

$promotion-product-tile-color: color(secondary-active);
$promotion-product-border: none;

// 2nd type of a promo message
$promotion-approaching-discount-background: color(global-background);
$promotion-approaching-discount-margin: rem-calc(20 0 0);
$promotion-product-font: 500 #{rem-calc(12)} / 1 $font-tertiary;
$promotion-product-background: color(secondary);
$promotion-icon-color: color(success);
$promotion-product-color: color(secondary-active);
$promotion-product-padding: rem-calc(0);
$promotion-product-text-align: center;

@import "@lora/05-components/promotion";