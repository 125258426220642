$footer-social-link-color: color(dark);
$footer-social-link-hover-color: color(secondary-active);
$footer-social-link-background: color(light);
$footer-social-link-border-radius: 50%;
$footer-social-link-border: rem-calc(1) solid color(border);
$footer-social-title-color: color(dark);
$footer-social-title-text-transform: uppercase;
$footer-social-title-text-transform--large: $footer-social-title-text-transform;
$footer-social-link-padding: rem-calc(6);
$footer-social-list: (
    messenger: (
        enabled: true,
        icon: messenger,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    facebook: (
        enabled: true,
        icon: facebook,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    twitter: (
        enabled: true,
        icon: twitter,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    google: (
        enabled: true,
        icon: google,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    pinterest: (
        enabled: true,
        icon: pinterest,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    instagram: (
        enabled: true,
        icon: instagram,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
    youtube: (
        enabled: true,
        icon: youtube,
        icon-height: rem-calc(18),
        icon-width: rem-calc(18)
    ),
);

@import "@lora/05-components/footer/footer-social";