/*------------------------------------*\
        #FOOTER NAVIGATION
\*------------------------------------*/

$footer-nav-link-level1-font: 100 #{rem-calc(12)} / 1.4 $font-primary;
$footer-nav-link-level1-font--large: $footer-nav-link-level1-font;
$footer-nav-link-level1-color: color(dark);
$footer-nav-link-level1-color--large: $footer-nav-link-level1-color;
$footer-nav-link-level1-text-transform: uppercase;
$footer-nav-link-level1-text-transform--large: $footer-nav-link-level1-text-transform;
$footer-nav-link-level1-hover-color: color(secondary-active);

$footer-nav-link-level2-font: 100 #{rem-calc(12)} / 1.8 $font-primary;
$footer-nav-link-level2-font--large: $footer-nav-link-level2-font;
$footer-nav-link-level2-color: color(dark);
$footer-nav-link-level2-color--large: $footer-nav-link-level2-color;
$footer-nav-link-level2-hover-color: color(secondary-active);

@import "@lora/05-components/footer/footer-nav";