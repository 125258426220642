$product-set-subtitle-font: 500 #{rem-calc(14)} / 1.4 $font-primary;
$product-set-separator-title-display: none;
$product-set-separator-color: color(light);

// Separator: before element
$product-set-separator-before-border-top: 0;

// Product set list and item
$product-set-item-margin: rem-calc(0 0 35);

@import '@lora/05-components/product/product-set';