// Header
$header-main-area-padding--large: rem-calc(9 25 0 25);
$header-main-area-border-bottom: 0;
$header-border-bottom: rem-calc(1) solid color(border);
$header-logo-padding--large: rem-calc(14 0 5);
$header-logo-max-width: rem-calc(64);
$header-logo-max-width--large: rem-calc(230);
$header-bottom-background--large: transparent;
$header-is-full-width: true;

// Header sticked
$header-sticked-background: color(light);
$header-sticked-logo-max-width: $header-logo-max-width;
$header-sticked-border-bottom: rem-calc(1) solid color(border);
$header-sticked-top-nav-item-margin--large: rem-calc(-3 0 0 15);
$header-sticked-top-nav-item-padding--large: rem-calc(0 0 0 10);
$header-sticked-search-button-padding--large: rem-calc(0 10);

// Hamburger
$header-hamburger-navigation-is-fullwidth: false;

// Search button
$header-search-button-background--large: color(global-background);
$header-search-button-color--large: color(primary);
$header-search-button-color-hover--large: color(secondary-active);
$header-search-button-active-after-border: none;

$header-search-button-icon-color-hover--large: color(secondary-active);
$header-search-button-icon-color--large: $header-search-button-color--large;
$header-search-button-padding--large: rem-calc(17 14);
$header-search-button-label-margin: rem-calc(0 0 0 8);
$header-search-button-opened-color: color(secondary-active);
$header-search-button-active-icon-color: color(secondary-active);
$header-search-button-active-border--large: rem-calc(1) solid color(secondary-active);
$header-search-button-opened-background: transparent;
$header-search-button-label-active-margin: $header-search-button-label-margin;
$header-search-button-active-padding--large: $header-search-button-padding--large;
$header-search-button-icon-height: rem-calc(16);
$header-search-button-icon-width: rem-calc(16);
$header-search-button-background-hover--large: transparent;
$header-search-button-border--large: rem-calc(1) solid color(global-background);
$header-search-button-border-hover--large: rem-calc(1) solid color(secondary-active);
$header-search-button-icon-color-hover--large: color(secondary-active);
$header-search-button-border-radius--large: rem-calc(5);
$header-top-nav-item-account-display--large: flex;

// Top nav item
$header-top-nav-right-padding--large: rem-calc(40 0 0);
$header-top-nav-item-separator-color: transparent;
$header-top-nav-item-padding--large: rem-calc(0 0 0 10);
$header-top-nav-item-margin--large: $header-top-nav-item-padding--large;
$header-top-nav-item-font-size--large: rem-calc(10);
$header-top-nav-item-font-weight--large: 300;
$header-top-nav-item-hover-color: color(secondary-active);
$header-sticked-top-nav-item-level-1-max-width--large: none;
$header-top-nav-item-separator-display: none;

// User button
$header-user-link-account-sticky-login-icon: user;
$header-user-link-account-sticky-login-icon-color: color(dark);
$header-user-link-account-sticky-login-icon-hover-color: color(primary-active);
$header-user-link-account-sticky-login-icon-width: rem-calc(17);
$header-user-link-account-sticky-login-icon-height: rem-calc(20);
// Email sign up
$header-top-nav-signup-text-display: none;

// Loyalty
$header-top-nav-loyalty-icon-display: inline-block;

$header-hamburger-border--large: none;
$header-hamburger-border-width--large: none;

@import "@lora/04-layout/header";