// Accordion Item
$accordion-item-border-bottom-collapsed: null;
$accordion-item-collapsed-first-border-top: $accordion-item-border-bottom-collapsed;

// Accordion Title
$accordion-title-font: 500 #{rem-calc(12)} / rem-calc(16) $font-tertiary;
$accordion-title-text-transform: none;
$accordion-title-text-transform--large: none;

// Accordion Title Secondary
$accordion-title-secondary-collapsed-color: color(primary-active);
$accordion-title-secondary-color-expanded: color(primary-active);
$accordion-title-secondary-background-color-expanded: none;
$accordion-title-secondary-collapsed-font: $accordion-title-font;
$accordion-title-secondary-collapsed-font--large: $accordion-title-secondary-collapsed-font;
$accordion-title-secondary-padding--large: rem-calc(15 49 15 35);
$accordion-content-inner-padding-collapsed--large: rem-calc(20 35 20);

@import "@lora/05-components/accordion";