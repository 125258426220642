$account-table-cell-header-background: color(light);
$account-table-cell-header-padding: rem-calc(35 10 35 20);
$account-table-cell-header-padding--large: rem-calc(35 20 35 15);
$account-table-cell-padding: rem-calc(24 10 24 15);
$account-table-cell-status-font: normal #{rem-calc(12)} / 1.5 $font-primary;
$account-table-cell-color-hover: color(light);
$account-table-cell-background-hover: color(secondary-active);
$account-table-row-box-shadow-hover: none;
$account-table-link-details-color: color(dark);
$account-table-link-details-hover-color: color(light);
$account-table-cell-header-color: color(dark);
$account-table-cell-header-sort-padding: rem-calc(35 25 35 15);
$account-table-cell-header-sort-icon-color: color(dark);
$account-table-cell-header-sort-current-icon-color: color(light);
$account-table-cell-header-sort-current-icon-background-color: color(dark);
$account-table-cell-header-sort-icon: arrow-down;
$account-table-cell-header-sort-active-icon: arrow-top;
$account-table-cell-background: color(global-background);
$account-table-cell-background--large: $account-table-cell-background;
@import "@lora/05-components/account/account-table";