$font-primary: "HelveticaNeue", sans-serif;
$font-secondary: "MillerDisplay", sans-serif;
$font-primary-light: "HelveticaNeue-Light", sans-serif;
$font-tertiary: "HelveticaNeue-Medium", sans-serif;
$font-engraving: "Gravograph VICTORIA 2L", sans-serif;

$font-list: (
    ('HelveticaNeue', 'HelveticaNeue', normal, normal),
    ('HelveticaNeue-Light', 'HelveticaNeue-Light', 100, normal),
    ('HelveticaNeue-Medium', 'HelveticaNeue-Medium', 500, normal),
    ('MillerDisplay', 'MillerDisplay-Light', normal, normal),
    ('Gravograph VICTORIA 2L', 'Gravograph-VICTORIA-2L', 500, normal)
);

@import "@lora/font-settings";