$product-tile-grid-list-align-items: flex-start;
$product-tile-default-text-align: center;

// Product name
$product-tile-grid-name-text-align: left;
$product-tile-name-font-size: rem-calc(14);
$product-tile-name-font-family: $font-tertiary;
$product-tile-name-line-height: 1.43;
$product-tile-name-text-transform: uppercase;
$product-tile-name-text-transform--large: $product-tile-name-text-transform;
$product-tile-name-color-hover: color(secondary-active);
$product-tile-name-color-visited: color(dark);
$product-tile-button-min-width--large: 100%;
$product-tile-name-margin: rem-calc(8 0);

// Product description
$product-tile-description-margin: rem-calc(5 0 8);
$product-tile-description-max-lines: 1;
$product-tile-description-line-height: 1.3;

// Product info
$product-tile-grid-info-item-align-self: flex-start;
$product-tile-info-min-height: rem-calc(56);
$product-tile-info-item-align: center;

// Product rating
$product-tile-rating-align: center;
$product-tile-rating-padding: rem-calc(0 2 0 0);
$product-tile-rating-margin: rem-calc(0 0 10);

// Product price
$product-tile-price-font: 500 #{rem-calc(14)} / rem-calc(20) $font-tertiary;
$product-tile-price-font--large: $product-tile-price-font;

// Product swatch
$product-tile-swatch-name-gap: rem-calc(5);
$product-tile-swatch-selected-font-size: rem-calc(12);
$product-tile-swatch-selected-color: color(primary-active);
$product-tile-swatch-selected-font-weight: 400;
$product-tile-variations-label-font: 400 #{rem-calc(12)} / rem-calc(16) $font-primary;

// Grid view
$product-tile-grid-figure-padding: rem-calc(30 10 0 0);

// Variation
$product-tile-variation-group-margin: rem-calc(10) auto;
$product-tile-variations-width--large: 100%;
$product-tile-variations-margin: 0;
$product-tile-variations-padding: 0;

// Product comparison button
$product-tile-comparison-button-min-width--large: rem-calc(270);

// Tile Selectable
$product-tile-selectable-secondary-active-border-color: color(dark);
$product-tile-swatch-group-padding: rem-calc(0 8);
$product-tile-swatch-group-padding--large: rem-calc(0 25);
$product-tile-swatch-group-swatches-padding: 0;

// Product actions
$product-tile-actions-margin: rem-calc(8 0 0);
$product-tile-add-bag-text-transform: uppercase;

$product-tile-wishlist-right: rem-calc(8);
$product-tile-wishlist-top: rem-calc(8);

@import "@lora/05-components/product/product-tile";