$scrollable-arrow-sizes: (
    default: (
        arrow-width: rem-calc(40),
        arrow-height: rem-calc(40),
        arrow-icon-width: rem-calc(40),
        arrow-icon-height: rem-calc(40),
        arrow-icon: arrow-right-without-bg
    ),
    small: (
        arrow-width: rem-calc(30),
        arrow-height: rem-calc(30),
        arrow-icon-width: rem-calc(30),
        arrow-icon-height: rem-calc(30),
        arrow-icon: arrow-right-without-bg
    ),
    medium: (
        arrow-width: rem-calc(35),
        arrow-height: rem-calc(35),
        arrow-icon-width: rem-calc(35),
        arrow-icon-height: rem-calc(35),
        arrow-icon: arrow-right-without-bg
    )
);

$scrollable-arrow-theme: (
    light: (
        background: color(light),
        color: color(primary),
        box-shadow: rem-calc(0 2 4 0) rgba(color(dark), 0.15)
    ),
    dark: (
        background: color(dark),
        color: color(light)
    )
);

$scrollable-arrow-border-radius: 50%;

@import "@lora/05-components/scrollable";