// Title
$content-hero-title-font: 500 #{rem-calc(26)} / rem-calc(30) $font-secondary;
$content-hero-title-font--large: 500 #{rem-calc(34)} / rem-calc(38) $font-secondary;
$content-hero-title-letter-spacing: rem-calc(0.3);
$content-hero-title-color--large: color(text);

// Description
$content-hero-description-font: 100 #{rem-calc(14)} / rem-calc(18) $font-primary;
$content-hero-description-font--large: #{rem-calc(14)} / rem-calc(22) $font-primary;
$content-hero-description-letter-spacing: rem-calc(0.3);
$content-hero-description-text-transform--large: none;
$content-hero-description-color--large: color(text);
$content-hero-button-display: block;

// label
$content-hero-label-color: color(primary-active);
$content-hero-label-color--large: $content-hero-label-color;
$content-hero-label-padding: rem-calc(7 18);
$content-hero-label-background-padding: rem-calc(7 18);
$content-hero-label-border-left: rem-calc(3) solid color(secondary-active);

// Layout
$content-hero-body-padding: rem-calc(25 0 40);

@import "@lora/05-components/content/content-hero";