//Title
$section-title-font: 300 #{rem-calc(26)} / 1.15 $font-secondary;
$section-title-font--large: 300 #{rem-calc(34)} / 1.15 $font-secondary;
$section-title-text-align--large: left;
$section-title-margin: rem-calc(0 0 20);
$section-title-margin--large: rem-calc(0 0 12);

// View more
$section-view-more-link-font: 300 #{rem-calc(12)} / rem-calc(18) $font-primary;
$section-view-more-link-font--large: 300 #{rem-calc(14)} / rem-calc(20) $font-primary;
$section-view-more-link-text-transform: none;
$section-view-more-margin--large: rem-calc(0);
$section-view-more-link-text-transform--large: $section-view-more-link-text-transform;

// Headings
$section-headings-max-width--large: 70%;

// Secondary
$section-secondary-title-font: 500 #{rem-calc(14)} / 1.4 $font-primary;
$section-secondary-title-font--large: $section-secondary-title-font;

// Description
$section-description-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$section-description-font--large: $section-description-font;
$section-description-color--large: color(text-secondary);
$section-description-text-align--large: left;

@import "@lora/05-components/section";