$progress-bar-height: rem-calc(9);
$progress-bar-background-color: color(global-background);
$progress-bar-value-background-color: color(secondary-active);
$progress-bar-border: rem-calc(1) solid color(disabled);
$progress-bar-padding: rem-calc(0);
$progress-bar-border-radius: rem-calc(7.5);
$progress-bar-margin: rem-calc(10 4);
$progress-bar-before-background-color: color(light);
$progress-bar-after-background-color: transparent;
$progress-bar-before-active-icon: tick-1;
$progress-bar-before-icon-color: color(disabled);
$progress-bar-before-icon-size: rem-calc(8);
$progress-bar-before-active-icon-size: rem-calc(9);
$progress-bar-point-description-font: 400 #{rem-calc(12)} / rem-calc(15) $font-primary;
$progress-bar-point-description-active-font: 400 #{rem-calc(12)} / rem-calc(15) $font-primary;
$progress-bar-point-price-active-color: color(dark);
$progress-bar-point-price-font: 400 #{rem-calc(12)} / rem-calc(15) $font-primary;
$progress-bar-point-price-active-font: 700 #{rem-calc(12)} / rem-calc(15) $font-tertiary;

@import "@lora/05-components/progress-bar";