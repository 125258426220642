$form-text-field-radius: 0;
$form-text-field-border: 0;
$form-text-field-border-hover: 0;
$form-text-field-border-focus: 0;
$form-text-field-box-shadow: rem-calc(0 2 0 0) color(element-background);
$form-text-field-box-shadow-hover: $form-text-field-box-shadow;
$form-text-field-box-shadow-focus: $form-text-field-box-shadow;
$form-text-field-padding: rem-calc(0 20 0 0);
$form-text-field-height: rem-calc(50);
$form-text-field-line-height: rem-calc(23);
$form-text-field-color-hover: color(text);
$form-text-field-textarea-min-height: auto;
$form-text-field-textarea-padding: rem-calc(10 20 10 0);
$form-text-field-background: color(light);
$form-text-field-font-size: rem-calc(14);
$form-text-field-font-weight: 500;
$form-text-field-font-weight-hover: $form-text-field-font-weight;
$form-text-field-counter-font-size: rem-calc(10);
$form-text-field-requirements-item-font: 400 #{rem-calc(12)} / #{rem-calc(14)} $font-primary;
$form-text-field-requirements-item-margin: rem-calc(0 0 6);
$form-text-field-requirements-item-success-color: color(text-secondary);
$form-text-field-requirements-item-icon-margin: rem-calc(0 8 0 0);

@import "@lora/05-components/form/text-field";