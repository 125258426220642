$tabs-tab-active-after-border-bottom: rem-calc(1) solid color(dark);
$tabs-tab-after-border-bottom: rem-calc(1) solid color(border);
$tabs-tab-after-width: 100%;
$tabs-tab-after-transition: width 0.1s, border 0.1s;
$tabs-tab-font: 500 #{rem-calc(14)} / rem-calc(20) $font-primary;
$tabs-tab-font--large: 100 #{rem-calc(26)} / rem-calc(30) $font-secondary;
$tabs-tab-text-transform: uppercase;
$tabs-tab-active-font: $tabs-tab-font;
$tabs-tab-margin--large: rem-calc(0);
$tabs-tab-margin: rem-calc(0);
$tabs-tab-active-font--large: $tabs-tab-font--large;
$tabs-tab-letter-spacing: rem-calc(0.3);
$tabs-tab-letter-spacing--large: $tabs-tab-letter-spacing;
$tabs-tab-hover-color: color(secondary-active);
$tabs-content-margin--large: rem-calc(30 0 0);

// Tabs tertiary
$tabs-tertiary-tab-color: color(text-secondary);
$tabs-tertiary-tab-font: 500 rem-calc(14) $font-primary;
$tabs-tertiary-tab-active-color: color(dark);
$tabs-tertiary-controls-background-color: transparent;
$tabs-tertiary-tab-text-transform: uppercase;
$tabs-tertiary-tab-text-align: left;
$tabs-tertiary-tab-padding--large: rem-calc(15 0);

// Tabs secondary
$tabs-secondary-tab-font-size: rem-calc(14);
$tabs-secondary-tab-font-size--large: rem-calc(14);
$tabs-secondary-tab-line-height: rem-calc(20);
$tabs-secondary-tab-line-height--large: rem-calc(20);
$tabs-secondary-tab-padding: rem-calc(10 45 10 0);
$tabs-secondary-tab-padding--large: rem-calc(10 80 10 0);
$tabs-secondary-content-font--large: #{rem-calc(14)} / rem-calc(20) $font-primary;
$tabs-secondary-controls-width--large: 100%;
$tabs-secondary-controls-background: transparent;
$tabs-secondary-tab-active-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-tertiary;
$tabs-secondary-tab-font-family: $font-primary;
$tabs-secondary-tab-active-color: color(dark);
$tabs-secondary-tab-active-font-weight: 500;
$tabs-secondary-tab-active-font-weight--large: 500;
$tabs-secondary-control-items-border-bottom: none;
$tabs-secondary-controls-padding: rem-calc(0 0 0 25);
$tabs-secondary-control-items-divider-side-offset: rem-calc(12);
$tabs-secondary-tab-divider-border-bottom-width: 1px;

// Tabs tertiary
$tabs-tertiary-tab-color: color(text-secondary);
$tabs-tertiary-tab-font: 300 rem-calc(14) $font-primary;
$tabs-tertiary-tab-active-color: color(dark);
$tabs-tertiary-tab-text-transform: uppercase;
$tabs-tertiary-tab-text-transform--large: $tabs-tertiary-tab-text-transform;
$tabs-tertiary-tab-active-font-weight: 500;
$tabs-tertiary-controls-background-color: color(light);
$tabs-tertiary-control-items-padding: 0;

@import "@lora/05-components/tabs";