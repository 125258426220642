@import '@root/05-components/checkout/checkout-shipping';

/* stylelint-disable */
.checkout_section {
    .formfield_city,
    .formfield_address1,
    .formfield_address2 {
        clear: left;
    }
}

.c-modal .c-ngcheckout__storepickup {
    +.c-separator {
        display: none;

        +.c-ngcheckout-selection {
            display: none;
        }
    }

    &:has(.c-ngcheckout-selection__header) {
        +.c-separator {
            display: block;

            +.c-ngcheckout-selection {
                display: block;
            }
        }
    }
}