/// Bottom margin of headers.
/// @type Number
$headings-margin-bottom: rem-calc(20);
$headings-lineheight: 1.3;

/// Font family for header elements.
/// @type String | List
$headings-font-family: $font-secondary;

/// @type Map
$headings-sizes: (
    small: (
        'h1': 26,
        'h2': 20,
        'h3': 19,
        'h4': 18,
        'h5': 14,
        'h6': 12,
    ),
    large: (
        'h1': 34,
        'h2': 30,
        'h3': 24,
        'h4': 18,
        'h5': 14,
        'h6': 12,
    ),
);

@import "@lora/03-base/headings";
