$error-callout-padding: rem-calc(150 15 20);
$error-callout-padding--large: rem-calc(100) 19%;
$error-callout-color: color(primary);
$error-callout-color--large: color(secondary);
$error-callout-text-align: left;
$error-callout-text-align--large: left;
$error-callout-title-text-align: inherit;
$error-callout-title-text-align--large: inherit;
$error-callout-info-text-align: $error-callout-title-text-align;
$error-callout-info-text-align--large: $error-callout-title-text-align--large;

// Error Callout Title
$error-callout-title-font: #{rem-calc(26)} / rem-calc(30) $font-secondary;
$error-callout-title-font--large: #{rem-calc(34)} / rem-calc(38) $font-secondary;
$error-callout-title-text-align: left;
$error-callout-title-text-align--large: left;
$error-callout-title-margin: rem-calc(0 0 15);
$error-callout-title-margin--large: rem-calc(10 0 25);
$error-callout-title-max-width--large: rem-calc(400);

// Error Callout Subtitle
$error-callout-subtitle-font: #{rem-calc(12)} / rem-calc(16) $font-primary;
$error-callout-subtitle-font--large: #{rem-calc(14)} / rem-calc(20) $font-primary;
$error-callout-subtitle-text-align: $error-callout-title-text-align;
$error-callout-subtitle-text-align--large: $error-callout-title-text-align--large;
$error-callout-subtitle-text-transform: none;
$error-callout-subtitle-margin--large: rem-calc(0 0 20);

@import "@lora/05-components/error-callout";