$swatch-sizes: (
    default: (
        swatch-size: rem-calc(28),
        border-size: rem-calc(2)
    ),
    small: (
        swatch-size: rem-calc(24),
        border-size: rem-calc(2.15)
    ),
    medium: (
        swatch-size: rem-calc(36),
        border-size: rem-calc(4)
    ),
    large: (
        swatch-size: rem-calc(49),
        border-size: rem-calc(7)
    ),
    x-large: (
        swatch-size: rem-calc(49),
        border-size: rem-calc(4)
    )
);

$swatch-selected-box-shadow: none;
$swatch-selected-border-color: color(primary);
$swatch-plp-margin: 0 rem-calc(4);

@import '@lora/05-components/swatch';