$form-label-color: color(primary-active);
$form-label-float-left: 0;
$form-label-float-padding: rem-calc(0);
$form-label-float-font-size: rem-calc(14);
$form-label-float-color: color(primary);
$form-label-float-focus-color: color(dark);
$form-label-float-focus-padding: rem-calc(0 5 0);
$form-label-float-focus-font-size: rem-calc(10);
$form-label-line-height: 1.43;
$form-label-float-text-transform: none;
$form-label-float-font-weight: 500;

@import "@lora/05-components/form/label";