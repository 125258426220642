// Minicart global style
$minicart-background: none;
$minicart-subtotal-background: none;
$minicart-width: rem-calc(400);

// Header
$minicart-header-padding: rem-calc(19 65 19 35);

// Empty minicart
$minicart-empty-width: rem-calc(670);
$minicart-empty-padding: rem-calc(34 55 36);
$minicart-empty-content-background: color(global-background);
$minicart-empty-text-font: #{rem-calc(26)} / rem-calc(30) $font-secondary;
$minicart-empty-text-margin: rem-calc(0 0 35);
$minicart-empty-title-margin: rem-calc(0 0 10);
$minicart-empty-content-width: 55%;
$minicart-empty-content-max-width: $minicart-empty-content-width;
$minicart-empty-text-margin: rem-calc(0 70 20);
$minicart-empty-checkout-link-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$minicart-empty-recommendation-width: 45%;
$minicart-empty-recommendation-padding: rem-calc(20 10 30 30);
$minicart-empty-background: color(global-background);
// Minicart Table
$minicart-th-details-font: #{rem-calc(26)} / rem-calc(30) $font-secondary;
$minicart-th-padding: rem-calc(75 15 15);
$minicart-th-details-padding: rem-calc(75 15 15 100);
$minicart-th-detail-text-transform: uppercase;
$minicart-th-price-padding: rem-calc(75 100 15 15);
$minicart-td-thumbnail-width: rem-calc(270);
$minicart-td-thumbnail-padding: rem-calc(5 15 5 100);
$minicart-td-vertical-align: top;
$minicart-td-price-padding: rem-calc(0 100 10 15);

// Minicart Product
$minicart-product-title-font: 500 #{rem-calc(14)} / rem-calc(16) $font-tertiary;
$minicart-product-title-margin: rem-calc(0 0 10);
$minicart-product-title-text-transform: uppercase;
$minicart-product-description-color: color(text-secondary);
$minicart-product-description-margin: rem-calc(0 0 30 0);

// Minicart items container
$minicart-items-padding: rem-calc(10 30);

// Minicart product item
$minicart-item-content-padding: rem-calc(24 20 17);
$minicart-item-content-hover-border-color: color(primary);

// Product Remove Icon
$minicart-remove-icon-display: false;
$minicart-remove-text-transform: capitalize;
$minicart-remove-font: #{rem-calc(12)} / rem-calc(18) $font-primary;
$minicart-remove-margin: rem-calc(30 0 25);

// Minicart Actions
$minicart-actions-background: none;
$minicart-actions-padding: rem-calc(30 100);
$minicart-header-border: rem-calc(1) solid color(border);
// Footer
$minicart-footer-border: none;
$minicart-footer-content-background: color(global-background);
$minicart-footer-content-padding: rem-calc(15 30 10);

// Recommendation
$minicart-recommendation-padding: rem-calc(32 57 52 53);

// Minicart Subtotal
$minicart-subtotal-title-padding: rem-calc(15 30 15 100);
$minicart-subtotal-price-padding: rem-calc(15 100 15 15);
$minicart-subtotal-title-font: 700 #{rem-calc(14)} / rem-calc(16) $font-primary;
$minicart-subtotal-price-font: 700 #{rem-calc(14)} / rem-calc(16) $font-primary;

@import "@lora/05-components/minicart";